import {saveAffiliation, trackEvent} from "../../library/analytics";
import {removeOverlay} from "../../library/overlay";
import {hideMenuRight, hideMenuRightFull, hideMenuLeftFull} from "../../library/menu-right";
import {getDeviceType} from "../../library/device-type";
import {getCookie, setCookie, delAllCookies} from "../../library/cookies";
import {hidePopup} from "./slide-up-popup";
import {waitingDialog} from "../../library/waiting-dialog";
import {popupInfoDialog} from "../../library/info-dialog";
import {ga4TrackLinkEvent} from "../../website/webpack/modules/analytics";
import {isMobile, isTablet} from "../../website/webpack/modules/devices";
import {
    acceptAllCookies,
    acceptAnalyticsCookies, acceptBasicCookies, acceptMarketingCookies,
    acceptNoCookies,
    deleteAllCookies
} from "../../website/webpack/modules/cookies";
import {changeEvent, clickEvent} from "../../website/webpack/modules/events";
import {launchNewsletterPopup} from "./newsletter";

window.showRelatedProducts = true;

let whatsappKey = document.querySelector('#whatsapp-key');
let htmlTag = document.getElementsByTagName('html');
let isThankUPage = document.getElementById('thank-u-page');
if (!isThankUPage && whatsappKey && (isMobile() || isTablet())) {
    let key = whatsappKey.value;
    let script = document.createElement('script');
    script.setAttribute('src','https://widget.tochat.be/bundle.js');
    script.dataset.key = key;
    document.head.appendChild(script);
    let helpWidgetType = document.getElementById('help-widget-type');
    if (helpWidgetType) helpWidgetType.dataset.type = 'whatsapp';
}

$(document).ready(function(){
    $('body').on('click', '.accept-all-cookies', function() {
        acceptAllCookies(365);
        $("#modal-cookies").modal('hide');
        // $('#offcanvas-cookies').remove();
        // $('#offcanvas-cookies-backdrop').remove();
        $('body').css('overflow', '');
        launchNewsletterPopup();
    });
    $('body').on('click', '.accept-basic-analytics-cookies', function() {
        deleteAllCookies();
        acceptAnalyticsCookies(7);
        acceptBasicCookies(7);
        $('#offcanvas-cookies').remove();
        $('#offcanvas-cookies-backdrop').remove();
        $('body').css('overflow', '');
        launchNewsletterPopup();
        dataLayer.push({
            event: 'cookies',
            method: 'accept_analytics_and_basic'
        });
    });
    $('body').on('click', '.show-modal-config-cookies', function() {
        // $('#offcanvas-cookies').remove();
        // $('#offcanvas-cookies-backdrop').remove();
        $('body').css('overflow', '');
        $("#modal-cookies").modal('hide');
        showModalConfigCookies();
    });
    $('body').on('click', '.decline-all-cookies', function() {
        acceptAnalyticsCookies(365);
        acceptBasicCookies(365);
        $("#modal-cookies").modal('hide');
        // $('#offcanvas-cookies').remove();
        // $('#offcanvas-cookies-backdrop').remove();
        $('body').css('overflow', '');
        launchNewsletterPopup();
    });
    $('body').on('click', '#modal-cookies .signin-cookies', function() {
        acceptAllCookies(365, 'cookies_login');
        $("#modal-cookies").modal('hide');
        setTimeout(() => {
            if ($('#hmtLoginLink').is(":visible")) {
                location.replace($('#hmtLoginLink').attr('href'));
            } else {
                $('#hmtLogin').click();
            }
        }, 50);
    });

    // MSIE fix for Promises
    if (navigator.userAgent.indexOf('MSIE') !==-1 || navigator.appVersion.indexOf('Trident/') > -1) {
        $('head').append('<script src="//cdn.jsdelivr.net/npm/bluebird@3.7.2/js/browser/bluebird.min.js"></script>');
    }

    if (!String.prototype.startsWith) {
        window.showRelatedProducts  = false;
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }

    $('body').on('click', '.cookieChoiceButton', function() {
        //$('#cookieChoiceInfo').slideUp(350);
        $("#modal-cookies").modal('hide');
        //if(!localStorage.getItem('_tv_cookie_choice')){
        if (localStorage.getItem('ga:clientId')) { localStorage.removeItem('ga:clientId'); }
        if (localStorage.getItem('_tv_cookie_choice')) { localStorage.removeItem('_tv_cookie_choice'); }
        setCookie('_tv_cookie_consent', 'y', 365);
        setCookie('_tv_cookie_choice', 'ALL', 365);
        //}
        // Change temporary header height for return to default
        //$('#headCnt').css('height', '');
        //$('#breadcrumbDiv, .containerFluidWinstickerProduct').removeClass('remove-margin-top');
    });
    $('body').on('click', '.cookieConfigButton', function () {
        if ($('.new-header').length) showModalConfigCookies();
        else showOldModalConfigCookies();
    });
    /*$('body').on('hide.bs.modal', '#modal-cookies', function() {
        if (localStorage.getItem('ga:clientId')) { localStorage.removeItem('ga:clientId'); }
        if (localStorage.getItem('_tv_cookie_choice')) { localStorage.removeItem('_tv_cookie_choice'); }
        delAllCookies();
        localStorage.setItem('_tv_cookie_choice', 'none');
    });*/
    $('body').on('click', '.dismissCookiesX', function() {
        let cookieChoiceElement = document.getElementById('cookieChoiceInfo');
        if (cookieChoiceElement != null) {
            $('#cookieChoiceInfo').slideUp(350);
            //delAllCookies();

            if (localStorage.getItem('ga:clientId')) { localStorage.removeItem('ga:clientId'); }
            if(localStorage.getItem('_tv_cookie_choice')){localStorage.removeItem('_tv_cookie_choice');}
            delAllCookies();
            localStorage.setItem('_tv_cookie_choice', 'none');
            if (window.gtag) {
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                });
            }
            /*if (window.gtag) {
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });
            }*/
        }
    });
    $('body').on('click', '#set-cookie-choise', function() {
        let selected = false;
        if ($('#disable-cookie-all').is(':checked')) {
            /* Set the cookie with value ALL to permit save all cokies inside the localhost
               and delete the storage var if exist */
            if(localStorage.getItem('ga:clientId')){localStorage.removeItem('ga:clientId');}
            if(localStorage.getItem('_tv_cookie_choice')){localStorage.removeItem('_tv_cookie_choice');}
            setCookie('_tv_cookie_consent', 'y', 365);
            setCookie('_tv_cookie_choice', 'ALL', 365);
            if (window.gtag) {
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                });
            }
            selected = true;
        } else if ($('#disable-cookie-basic').is(':checked')) {
            /* Set the cookie with value BASIC to permit save only the necessary cookies inside the localhost
               and delete the storage var if exist */
            delAllCookies();
            if(localStorage.getItem('_tv_cookie_choice')){localStorage.removeItem('_tv_cookie_choice');}
            setCookie('_tv_cookie_consent', 'y', 90);
            setCookie('_tv_cookie_choice', 'BASIC', 90);
            /*if (window.gtag) {
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });
            }*/
            selected = true;
        } else if ($('#disable-cookie-none').is(':checked')) {
            /* Delete ALL cookies even the storage var from Google Analytics
               and save this choice inside the var storage browser */
            delAllCookies();
            localStorage.setItem('_tv_cookie_choice', 'none');
            /*$.post(
                '/ajax/information/cookies-message',
                {
                    type: 'none'
                },
                function (data) {
                    waitingDialog.hide();
                    if (data.message) {
                        popupInfoDialog(data.message);
                    }
                },
                'json'
            );*/
            if (window.gtag) {
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied'
                });
            }
            selected = true;
        }
        if (selected) {
            $('.modal').modal('hide');
            if ($('.modal-backdrop.fade.in').length) {
                $('.modal-backdrop.fade.in').hide();
            }
        }
    });

    clickEvent("#save-configuration-cookies", true, function() {
        if (fullCookiesAccepted()) {
            acceptAllCookies(365, 'cookies_config');
        } else if (noCookiesAccepted()) {
            acceptNoCookies();
            dataLayer.push({
                event: 'cookies_config',
                method: 'not_accept_any'
            });
        } else {
            deleteAllCookies();
            if (analyticsCookiesAccepted()) acceptAnalyticsCookies(7);
            if (marketingCookiesAccepted()) acceptMarketingCookies(7);
            if (basicCookiesAccepted()) acceptBasicCookies(7);

            if (analyticsCookiesAccepted() && marketingCookiesAccepted()) {
                dataLayer.push({
                    event: 'cookies_config',
                    method: 'accept_all'
                });
            } else if (analyticsCookiesAccepted()) {
                dataLayer.push({
                    event: 'cookies_config',
                    method: 'accept_analytics_and_basic'
                });
            } else if (marketingCookiesAccepted()) {
                dataLayer.push({
                    event: 'cookies_config',
                    method: 'accept_marketing_and_basic'
                });
            } else {
                dataLayer.push({
                    event: 'cookies_config',
                    method: 'accept_basic_only'
                });
            }
        }

        $('#modal-config-cookies').modal('hide');
        launchNewsletterPopup();
        //location.replace(location.href);
    });

    $("body").on("hide.bs.modal", "#modal-config-cookies", function () {
        if (getCookie('_tv_cookie_choice') === "") {
            acceptAllCookies(30, 'cookies_config_close');
            launchNewsletterPopup();
        }
    });

    changeEvent('#cookie-configuration-all', true, function(element) {
        let analyticsCheck = document.querySelector('#cookie-configuration-analytics');
        let marketingCHeck = document.querySelector('#cookie-configuration-marketing');
        if (element.checked) {
            analyticsCheck.disabled = true;
            marketingCHeck.disabled = true;
            analyticsCheck.checked = true;
            marketingCHeck.checked = true;
        } else {
            analyticsCheck.disabled = false;
            marketingCHeck.disabled = false;
        }
    });

    if (getCookie('_tv_cookie_choice') === "") {
        delAllCookies();
        sessionStorage.removeItem('_tv_user_country');
        sessionStorage.removeItem('_tv_user_cookie_country');
        localStorage.setItem('_tv_cookie_choice', 'none');
        /*if (window.gtag) {
            gtag('consent', 'update', {
                'ad_storage': 'denied',
                'analytics_storage': 'granted'
            });
        }*/
    }
    /*$(window).unload(function(){
        if (localStorage.getItem('_tv_cookie_choice')) { localStorage.removeItem('_tv_cookie_choice'); }
    });*/
    window.addEventListener('pagehide', function(){
        if (localStorage.getItem('_tv_cookie_choice')) { localStorage.removeItem('_tv_cookie_choice'); }
    });

    saveAffiliation();

    $(document).on('click', '.overlay', function () {
        hideMenuRight();
        hidePopup();
        hideMenuRightFull();
        hideMenuLeftFull();
        if ($('.shopping-cart-body').length > 0) {
            trackEvent('ShoppingCart', 'Open/Close', '');
        }
    });
    $(document).on('click', '#menu-right-close', function () {
        hideMenuRight();
        hidePopup();
        if ($('.shopping-cart-body').length > 0) {
            trackEvent('ShoppingCart', 'Open/Close', '');
        }
    });

    //TODO STILL IN USE?
    $('.btn-untruncate-text').click(function(){
        let btn = $(this);
        btn.parent().parent().find('p').removeClass().parent().removeClass().addClass('col-xs-12');
        btn.parent().hide();
    });
    //TODO STILL IN USE?
    $('.buttonShowMore').click(function() {
        let showMore = $(this).parent().siblings('.showMore').css('display');
        if (showMore == "none") {
            $(this).removeClass('mdi-content-add');
            $(this).addClass('mdi-content-remove');
            $(this).parent().siblings('.showMore').css("display", "block");
            $(this).parent().siblings('.showMore').removeClass('hidden-xs');
            $(this).parent().siblings('.showMore').addClass('visible-xs');
            $(this).parent().siblings('.showMore').removeClass('hidden-sm');
            $(this).parent().siblings('.showMore').addClass('visible-sm');
        } else {
            $(this).removeClass('mdi-content-remove');
            $(this).addClass('mdi-content-add');
            $(this).parent().siblings('.showMore').css('display', 'none');
            $(this).parent().siblings('.showMore').removeClass('visible-xs');
            $(this).parent().siblings('.showMore').addClass('hidden-xs');
            $(this).parent().siblings('.showMore').removeClass('visible-sm');
            $(this).parent().siblings('.showMore').addClass('hidden-sm');
        }
    });

    $(document).on('click', '.overlay', function(){
        removeOverlay();
    });
    $(document).on('click', '#toTOP', function(){
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });

    setDeviceType();

    $(document).on('mouseup', '.ga4-track-link', function(){
        ga4TrackLinkEvent(this);
    });

    $(document).on('click', '.copy-from', function(){
        let copyFrom = this.dataset.copyFrom;
        if (copyFrom) {
            let input = document.getElementById(copyFrom);
            if (input) {
                input.select();
                input.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(input.value).then(() => {
                    this.classList.remove('btn-dark');
                    this.classList.add('btn-success');
                    this.querySelector('.tvi').classList.remove('tvi-copy');
                    this.querySelector('.tvi').classList.add('tvi-check-solid');
                });
            }
        }
    });
});

window.deviceType = 'd';
function setDeviceType() {
    let device = getDeviceType();
    switch (device) {
        case 'desktop':
            window.deviceType = 'd';
            break;
        case 'tablet':
            window.deviceType = 't';
            break;
        case 'mobile':
            window.deviceType = 'm';
            break;
    }
}

var amountScrolled = 300;
var scrollPercentAnalytics = [];
$(window).scroll(function() {
    if ( $(window).scrollTop() >= amountScrolled && $(window).scrollTop() <= (amountScrolled+100) ) {
        $('#toTOP').fadeIn('slow');
    } else if($(window).scrollTop() < (amountScrolled-1) && $(window).scrollTop() > (amountScrolled-100)) {
        $('#toTOP').fadeOut('slow');
    }

    var scrollPercent = parseFloat(100 * $(window).scrollTop() / ($(document).height() - $(window).height())).toFixed(0);
    if (!scrollPercentAnalytics.includes(scrollPercent)) {
        switch (scrollPercent) {
            case '25':
                trackEvent('Page', 'Scroll', scrollPercent);
                scrollPercentAnalytics.push(scrollPercent);
                break;
            case '50':
                trackEvent('Page', 'Scroll', scrollPercent);
                scrollPercentAnalytics.push(scrollPercent);
                break;
            case '90':
                trackEvent('Page', 'Scroll', scrollPercent);
                scrollPercentAnalytics.push(scrollPercent);
                break;
        }
    }

});

jQuery.fn.slideUpShow = function (time,callback) {
    if (!time)
        time = 200;
    var o = $(this[0]) // It's your element

    if (o.is(':hidden'))
    {
        var height = o.css({
            display: "block"
        }).height();

        o.css({
            overflow: "hidden",
            marginTop: height,
            height: 0
        }).animate({
            marginTop: 0,
            height: height
        }, time, function () {
            $(this).css({
                display: "block",
                overflow: "",
                height: "",
                marginTop: ""
            });
            if (callback)
                callback();
        });
    }

    return this; // This is needed so others can keep chaining off of this
};

jQuery.fn.slideDownHide = function (time,callback) {
    if (!time)
        time = 200;
    var o = $(this[0]) // It's your element
    if (o.is(':visible')) {
        var height = o.height();

        o.css({
            overflow: "hidden",
            marginTop: 0,
            height: height
        }).animate({
            marginTop: height,
            height: 0
        }, time, function () {
            $(this).css({
                display: "none",
                overflow: "",
                height: "",
                marginTop: ""
            });
            if (callback)
                callback();
        });
    }

    return this;
}

function showOldModalConfigCookies()
{
    let cookieChoiceElement = document.getElementById('cookieChoiceInfo');
    if (cookieChoiceElement != null) {
        $('#cookieChoiceInfo').slideUp(350);
    }
    waitingDialog.show();
    $.post(
        '/ajax/information/show-cookies-configuracion',
        {
            choiseNone: localStorage.getItem('_tv_cookie_choice') === 'none'
        },
        function (data) {
            waitingDialog.hide();
            if (data.view) {
                popupInfoDialog(data.view, true, true);
                setTimeout(function (){$.material.init();}, 300);
            }
        },
        'json'
    );
}

function showModalConfigCookies()
{
    $.post(
        '/ajax/information/show-cookies-configuration-modal',
        {},
        function (data) {
            if (data.view) {
                $(data.view).modal();
                setTimeout(function (){$.material.init();}, 300);
            }
            dataLayer.push({
                event: 'cookies',
                method: 'show_configuration'
            });
        },
        'json'
    );
}

function fullCookiesAccepted()
{
    return allCookiesAccepted() || (analyticsCookiesAccepted() && marketingCookiesAccepted() && basicCookiesAccepted());
}
function noCookiesAccepted()
{
    return !allCookiesAccepted() && !analyticsCookiesAccepted() && !marketingCookiesAccepted() && !basicCookiesAccepted();
}
function allCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-all').checked;
}
function analyticsCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-analytics').checked;
}
function marketingCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-marketing').checked;
}
function basicCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-basic').checked;
}